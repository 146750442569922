export const CAROUSEL_ITEMS = [
    {
        title: 'Princess',
        url: '/assets/sketches/1.png'
    },
    {
        title: 'Lines',
        url: '/assets/sketches/2.png'
    },
    {
        title: 'Japanese Fish',
        url: '/assets/sketches/3.png'
    },
    {
        title: 'Simple',
        url: '/assets/sketches/4.png'
    },
    {
        title: 'Japan',
        url: '/assets/sketches/5.png'
    },
    {
        title: 'Cute animals',
        url: '/assets/sketches/6.png'
    },
    {
        title: 'Color skulls',
        url: '/assets/sketches/7.png'
    },
    {
        title: 'Dragons',
        url: '/assets/sketches/8.png'
    },
    {
        title: 'Family',
        url: '/assets/sketches/9.png'
    },
    {
        title: 'Samurai',
        url: '/assets/sketches/10.png'
    },
    {
        title: 'Hieroglyphs',
        url: '/assets/sketches/11.png'
    },
    {
        title: 'Flowers',
        url: '/assets/sketches/12.png'
    },
    {
        title: 'Princess',
        url: '/assets/sketches/1.png'
    },
    {
        title: 'Lines',
        url: '/assets/sketches/2.png'
    },
    {
        title: 'Japanese Fish',
        url: '/assets/sketches/3.png'
    },
    {
        title: 'Simple',
        url: '/assets/sketches/4.png'
    },
    {
        title: 'Japan',
        url: '/assets/sketches/5.png'
    },
    {
        title: 'Cute animals',
        url: '/assets/sketches/6.png'
    },
    {
        title: 'Color skulls',
        url: '/assets/sketches/7.png'
    },
    {
        title: 'Dragons',
        url: '/assets/sketches/8.png'
    },
    {
        title: 'Family',
        url: '/assets/sketches/9.png'
    },
    {
        title: 'Samurai',
        url: '/assets/sketches/10.png'
    },
    {
        title: 'Hieroglyphs',
        url: '/assets/sketches/11.png'
    },
    {
        title: 'Flowers',
        url: '/assets/sketches/12.png'
    },
]