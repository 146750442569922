import React from 'react';

import { APPLE_DOWNLOAD_LINK, GOOGLE_DOWNLOAD_LINK } from '../../constants'

import { Carousel } from './components';
import styles from "./styles.module.scss";

const Home = () => (
    <>
        <div className={styles.previewContainer}>
            <div className={styles.preview}>
                <div className={styles.title}>Try the best tattoos on your body in 10 sec with no pain!</div>
                <div className={styles.subtitle}>Tattoon your body</div>
                <div className={styles.downloadLinks}>
                    <a href={APPLE_DOWNLOAD_LINK} target="_blank" className={styles.link} rel="noreferrer">
                        <img src="/assets/apple-qr.svg" className={styles.qrcode}/>
                        <div className={styles.separator} />
                        <img src="/assets/apple-download.svg" className={styles.download}/>
                    </a>
                    <a href={GOOGLE_DOWNLOAD_LINK} target="_blank" className={styles.link} rel="noreferrer">
                        <img src="/assets/google-qr.png" className={styles.qrcode}/>
                        <div className={styles.separator} />
                        <img src="/assets/google-download.svg" className={styles.download}/>
                    </a>
                </div>
            </div>

            <img src="/assets/example.png" className={styles.phoneImage}/>
        </div>

        <div className={styles.content}>
            <div className={styles.header}>
                <div className={styles.title}>Try the best tattoos on your body in 10 sec with no pain!</div>
                <div className={styles.subtitle}>Tattoon your body</div>
                <div className={styles.links}>
                    <a href={APPLE_DOWNLOAD_LINK} target="_blank" className={styles.link} rel="noreferrer">
                        <img src="/assets/apple-download.svg" className={styles.download}/>
                    </a>
                    <a href={GOOGLE_DOWNLOAD_LINK} target="_blank" className={styles.link} rel="noreferrer">
                        <img src="/assets/google-download.svg" className={styles.download}/>
                    </a>
                </div>
            </div>

            <div className={styles.contentGroups}>
                <div className={styles.contentGroup}>
                    <div className={styles.title}>Various  Sketches</div>
                    <div className={styles.content}>
                        <Carousel className={styles.carousel} />
                    </div>
                </div>

                <div className={styles.contentGroup}>
                    <div className={styles.title}>Trusted and granted by</div>
                    <div className={styles.content}>
                        <img src="/assets/aws.svg"/>
                        <img src="/assets/nvidia.svg"/>
                        <img src="/assets/microsoft.svg"/>
                        <img src="/assets/google.svg"/>
                    </div>
                </div>
            </div>
        </div>
    </>
);

export default Home;