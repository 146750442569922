import React from 'react';

import styles from "./styles.module.scss";

const PrivacyPolicy = () => (
    <div className={styles.container}>
        <h1 className={styles.header}>privacy policy</h1>
        <div className={styles.body}>
            <p>Welcome to Tattoon.net (the “Website”) and Tattoon (the “Application”), (both the “Services”), provided by Urbo Labs, Inc., a Delaware corporation, (“Company”, “we” or “us”). This Privacy Policy (the “Policy” or the “Privacy Policy”) is meant to help you understand what information we collect, why we collect it, how we process and store it and explain the rights that you have with regard to your personal data as well as our responsibilities regarding the processing of your personal data.</p>
            <p>For the purpose of this Policy any information that can directly or indirectly be used to identify a person shall be defined as “personal data”, “personally-Identifiable Information” or “personal information”.</p>
            <p>Please note that this policy does not apply to the privacy practices of third parties that we do not own or control, including but not limited to any third party websites, services, applications, online resources to which the Website or Application may link or which may provide a link to our Website or Application.</p>

            <h2>1. Personal data that we collect</h2>
            <p>
                <h3>Personally-Identifiable Information.</h3>
            </p>
            <p>
                We may collect the following personal data when you use the Services:
                <ul>
                    <li>Name (first name and/or family name);</li>
                    <li>email address;</li>
                    <li>phone numbers;</li>
                    <li>photographs, as long as they can be used to identify an individual;</li>
                    <li>name;</li>
                    <li>date of birth;</li>
                    <li>credit or debit card type, expiration date, and certain digits of your card</li>
                    <li>number (For security, we never store your full card number);</li>
                    <li>ZIP/postal code;</li>
                    <li>mobile phone number;</li>
                    <li>details of your purchase and payment history;</li>
                    <li>IP address;</li>
                    <li>log data;</li>
                </ul>
            </p>
            <p>You may always choose not to provide personally identifiable information, but if you so choose, certain parts of the Services may not be available to you. If you have registered an account with us, you will have agreed to provide your personally identifiable information in order to access the Services. This consent provides us with the legal basis we require under applicable law to process your data. You maintain the right to withdraw such consent at any time. If you do not agree to our use of your personal data in line with this Policy, please do not use our Services.</p>
            <h3>Non-Personally-Identifiable Information.</h3>
            <p>
                We may collect and aggregate non-personally identifiable information which is information which cannot be used by itself or in combination with other information to identify you as an individual. This information may include information such as:
                <ul>
                    <li>Information about your use of the Services;</li>
                    <li>contents that you publish, upload or create by using the Services; and</li>
                    <li>technical data, including the URL you are accessing the Website or Application from, IP address, unique device ID, information about network- and device capacity, type of browser, language and information about identification and OS.</li>
                </ul>
            </p>
            <h3>Cookies and Tracking Technologies.</h3>
            <p>We may use cookies and tracking technologies to collect personal data from users. Cookies are small data files stored on your device’s hard drive by a website or an application. Usually, the information that we collect automatically through cookies is statistical data and cannot be used to identify you as an individual. It helps us to improve our Website and Application and to deliver a better and more personalized service.</p>
            <p>Upon your visit of our Website or Application, we will ask you whether you wish us to use cookies. If you choose not to accept them, we shall not use them for your visit and use of our Services except to record that you have not consented to their use for any other purpose. If you choose not to use cookies or you prevent their use through your browser settings, you will not be able to use all the functionality of our Website and Application.</p>

            <h2>2. What we do with the information that we collect</h2>
            <p>We process your Personal data for the following purposes:</p>
            <ol>
                <li>to administer your account with us, to provide and make the Services accessible and personalized to you and to improve your user experience and to further make sure we deliver on our obligations to you as a user;</li>
                <li>to send you notices and messages;</li>
                <li>to inform you on updates of the Services, the Privacy policy or other contractual agreements;</li>
                <li>to improve and develop the Services, and to analyze your use of the Services;</li>
                <li>Maintain security in the technical functions of the Services and prevent misuse of the Services;</li>
                <li>to fulfill any contractual agreements, including protecting our rights, our property and security; and</li>
                <li>to fulfill requirements in mandatory applicable law.</li>
            </ol>

            <h2>3. Legal grounds</h2>
            <p>We process Personal data based on the following legal grounds:</p>
            <ol>
                <li>Fulfillment of contract. We process your Personal data to provide you with the Services in accordance with the agreement entered into between us and you as a subscriber, or to take necessary steps based on the requests of a user prior to entering into an agreement regarding the Services.</li>
                <li>Legitimate Interest. The processing of your Personal data can also be based on our legitimate interest to operate, administrate, maintain and improve our Website, Application and our Services, including responding to requests from you. This also includes sending information related to the Website and Application to you.</li>
            </ol>
            <p>You have the right to object to the processing of Personal data based on a legitimate interest. If we receive your objection, we will inform you on the basis on which we have balanced our legitimate interest against yours as an individual.</p>

            <h2>4. Disclosure of Personal Data</h2>
            <p>We will not disclose your personal information to any third parties and we do not share, sell, rent, or trade your personal information with third parties for their commercial purposes, however, we reserve the right to disclose information about you to third parties to third-parties for assistance in improving our Services. Additionally, we may disclose personal and non-personally identifiable information with third parties service providers, engaged by us to provide technical support, hosting services, testing, network security or statistical services, any such disclosure will be subject to confidentiality obligations.</p>
            <p>We may also disclose information about you to third-parties where we have a good faith belief that such disclosure is necessary in order to: (i) protect, enforce, or defend the legal rights, privacy, safety, or property of our Company, our affiliates or their employees, agents and contractors (including enforcement of our agreements and our terms of use); (ii) protect the safety, privacy, and security of our users and employees, owners, or members of the public; (iii) protect against fraud or for risk management purposes; (iv) comply with the law or legal process; or (v) respond to requests from public and government authorities.</p>

            <h2>5. Security</h2>
            <p>
                We are committed to ensuring that the information you provide to us is secure from accidental loss and from unauthorized access, use, alteration and disclosure. We have implemented suitable physical, electronic and organizational measures to safeguard and secure information and protect it from misuse, interference, loss and unauthorized access, modification and disclosure.
                <p>
                    <ul>
                        <li>All information you provide to us is stored on our secure servers behind firewalls.</li>
                        <li>We restrict access to personal information to employees, contractors and agents who are bound by confidentiality obligations and may be subject to discipline, including termination and legal action, if they fail to meet the obligations for data confidentiality and integrity.</li>
                        <li>Areas of our Website or Application that collect personal data use encryption or other types of pseudonymization.</li>
                        <li>In the event of a physical or technical incident, we will be able to restore the availability and access to personal data in a timely manner.</li>
                    </ul>
                </p>
            </p>
            <p>Please be aware that despite our best efforts, no data security measures can guarantee 100% security. The security and safety of your personal information is also your own responsibility. We advise you to keep your account log-in details confidential and not share them with anyone in order to prevent unauthorized access to your account and personal information.</p>

            <h2>6. Data Retention</h2>
            <p>We will retain your personal information for as long as is necessary for the performance of the contract between you and us and to comply with our legal obligations. In certain jurisdictions, you can request to have all your personal information deleted entirely, we will accommodate such requests within 30 days.</p>
            <p>
                Please note that if you request the erasure of your personal information, we may still retain some of your personal information, as follows:
                <ul>
                    <li>for our legitimate business interests, such as fraud detection and prevention and enhancing safety.</li>
                    <li>to the extent necessary to comply with our legal obligations. For example, for tax, legal reporting and auditing obligations.</li>
                    <li>information you have shared with others (e.g., Reviews, forum postings) may continue to be publicly visible on or through the Services, even after your account is canceled. Additionally, some copies of your information (e.g., log records) may remain in our database, but are disassociated from personal identifiers.</li>
                    <li>because we maintain the Services to protect from accidental or malicious loss and destruction, residual copies of your personal information may not be removed from our backup systems for a limited period of time.</li>
                </ul>
            </p>

            <h2>7. Children</h2>
            <p>We do not knowingly collect personal information from users under the age of thirteen (13). If you are a parent or guardian of a person under the age of thirteen (13) and you become aware of that the child has provided Personal data to us without your consent, please contact info@tattoon.net to exercise your access, rectification, erasure, limiting of processing and objection rights.</p>

            <h2>8. Your Rights</h2>
            <p>Consistent with applicable law, you may exercise any of the rights described in this section.</p>
            <p>
                <ol>
                    <li>You have an absolute right to object to the processing of your personal data for direct marketing. You also have the right to withdraw your prior given consent, if applicable. The withdrawal of your consent does not affect the lawfulness of the processing based on the consent before its withdrawal, and we may continue processing your personal data based on other legal grounds, except for direct marketing.</li>
                    <li>You have the right to request access and further information concerning the processing of your personal data, or request that we correct, rectify, complete, erase or restrict the processing of your personal data.</li>
                    <li>You have the right to obtain a copy of the personal data that we process relating to you free of charge once (1) every calendar year. For any additional copies requested by you, we may charge a reasonable fee based on administrative costs.</li>
                    <li>If the processing is based on the legal grounds consent or fulfillment of contract you have the right to data portability. Data portability means that you can receive the personal data that you have provided to us, in a structured, commonly used and machine-readable format, and have the right to transfer such data to another data controllers.</li>
                </ol>
            </p>

            <h2>9. Special Note to California Privacy Rights</h2>
            <p>California Civil Code Section 1798.83, also known as the “Shine the Light” law, permits consumers who are California residents to request a list of certain third parties to which we have disclosed personally identifiable information about you for their own direct marketing purposes. You may make one request per calendar year. In your request, please attest to the fact that you are a California resident and provide a current California address for your response. You may request this information in writing by contacting us at: info@tattoon.net. Please allow up to thirty (30) days for a response. Please note that such disclosure requirements apply only if we share our consumers’ personal information with third parties for them to directly market their own products to those consumers (which we currently do not do), instead of assisting us with our own business.</p>

            <h2>10. Changes to This Privacy Policy</h2>
            <p>We reserve the right to, at our own discretion, make changes to this Privacy Policy. If we do so, we will inform you upon your use of the Website or Application or by email if changes are extensive. If the changes require your consent, we will give you further notice about this in a way that is suitable under the circumstances and ask for your consent.</p>

            <h2>11. Contact Information</h2>
            <p>If you have any questions, comments, complaints or concerns regarding this Policy, you can contact us anytime at the following address: info@tattoon.net. We take complaints very seriously and will respond shortly after receiving written notice of your complaint.</p>
        </div>
    </div>
);

export default PrivacyPolicy;