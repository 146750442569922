import React from 'react';

import styles from "./styles.module.scss";

const TermsOfUse = () => (
    <div className={styles.container}>
        <h1 className={styles.header}>terms of use</h1>
        <div className={styles.body}>
            <p>This website https://tattoon.net (the “Website“) and the mobile application “tattoon” (the “Application“) are operated by Urbo Labs, Inc. (“Company“, “we” or “us“) and the following terms and conditions, together with any documents they expressly incorporate by reference (collectively, these “Terms of Service” or “TOS“), govern your access to and use of the Website and the Application including any content, functionality and services offered on or through the Website and the Application, and they are binding on all users.</p>
            <p>By using the Website and the Application, you accept and agree to abide by these Terms of Service and our Privacy Policy available at https://tattoon.net/privacy incorporated herein by reference. If you do not want to agree to these Terms of Service or the Privacy Policy, you should not access or use the Application.</p>

            <h2>1. Minimum age requirement</h2>
            <p>This Application is not intended for individuals under 13 years of age. If you are under 13, you are not authorized to use the Website and the Application and will not be provided access to any features of the Website and the Application.</p>

            <h2>2. Definitions</h2>
            <p>
                <ol>
                    <li>“Service” means tattoon’ mobile Application which allows users to edit, process, transfer and store their photos and all services offered through our Website.</li>
                    <li>“User” means any person with the minimum age requirement who accesses any portion of our Application.</li>
                    <li>“Content” means any material, existing or having existed on our Application in any fashion consistent with these TOS from any origin and in any form whether digital, electronic, posted, deleted, archived, embedded, linked or contained in any subpage of our Application or existing as data, designs, text, images, graphics, video recording, or code whether object, source or otherwise, and without limitation may generally be considered material which we have provided on or as part of our Application or which Users have provided, posted or uploaded to it, either manually or via social networks.</li>
                </ol>
            </p>

            <h2>3. User Account</h2>
            <p>
                <ol>
                    <li>User registration. You do not need to register an account with us in order to access and using the Services. However, certain features of the Website and the Application may be available to paid subscribers only, in such cases we may ask you to provide your full name, email address and create a secure password. It is a condition of your use of the Website and the Application that all the information you provide is correct, current and complete. You agree that all information you provide to register with the Website and the Application, is governed by our Privacy Policy and you consent to all actions we take with respect to your information consistent with our Privacy Policy.</li>
                    <li>Security of Username and Password. If you choose, or are provided with, a user name, password or any other piece of information as part of our security procedures, you must treat such information as confidential, and you must not disclose it to any other person or entity. You also acknowledge that your account is personal to you and agree not to provide any other person with access to Application account or portions of it using your user name, password or other security information. You agree to notify us immediately of any unauthorized access to or use of your user name or password or any other breach of security.</li>
                    <li>Account Termination. Your account with us is a privilege provided to you by us and exists and is maintained entirely at our discretion. At any time, with or without cause, we may terminate/suspend your account if we deem doing so to be necessary. You agree that you release us now and forever from any liability incurred by you as a result of our termination/suspension of your account and that you shall indemnify, defend and otherwise hold us harmless for any liability, claims, damages, liens, penalties, fines or fees (including attorney’s fees and/or accounting fees) which we incur or are brought against us by any party as a result, either directly or indirectly of our decision to terminate your account. You acknowledge that without limitation, grounds for termination of your account may include any violation of these Terms of Service by you or any party using your account, the order/request of any regulatory, investigatory, law enforcement, government or judicial agency/entity. You understand and acknowledge that our termination/suspension of your account does not require prior notice to you.</li>
                </ol>
            </p>

            <h2>4. Right to Amend</h2>
            <p>
                <ol>
                    <li>Right to Amend the Application. We reserve the right to withdraw or amend the Website and the Application, and any service, feature, functionality or material we provide on the Website and the Application, in our sole discretion without notice. We will not be liable if for any reason all or any part of the Website and the Application is unavailable at any time or for any period. From time to time, we may restrict access to some parts of the Website and the Application, or the entire Website and Application, to users, including paid subscribers.</li>
                    <li>Right to Disable Username and Password. We have the right to disable any user name, password or other identifier, whether chosen by you or provided by us, at any time if, in our opinion, you have violated any provision of these Terms of Service.</li>
                </ol>
            </p>

            <h2>5. Intellectual Property Rights</h2>
            <p>
                <ol>
                    <li>Intellectual Property Rights Ownership. The Website and the Application and their entire content, features and functionality (including but not limited to all information, software, text, displays, images, video and audio, and the design, selection and arrangement thereof), are owned by the Company, its licensors or other providers of such material and are protected by United States and international copyright, trademark, patent, trade secret and other intellectual property or proprietary rights laws.</li>
                    <li>
                        License to Use. Once you become a User, you are allowed to use the Website and the Application for your personal, non-commercial use only. You must not reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store or transmit any of the material on our Website and Application, except as follows:
                        <ul>
                            <li>(a) Your tablet or mobile device may temporarily store copies of such materials in RAM incidental to your accessing and viewing those materials.</li>
                            <li>(b) You may print or download one copy of a reasonable number of pages of the Website and the Application for your own personal, non-commercial use and not for further reproduction, publication or distribution.</li>
                        </ul>
                    </li>
                    <li>
                        Restrictions. You must not:
                        <ul>
                            <li>(a) Modify copies of any materials from this Website and Application.</li>
                            <li>(b) Use any illustrations, photographs, video or audio sequences or any graphics separately from the accompanying text.</li>
                            <li>(c) Delete or alter any copyright, trademark or other proprietary rights notices from copies of materials from this site.</li>
                        </ul>
                    </li>
                    <li>Breaching the Terms of Service. If you print, copy, modify, download or otherwise use or provide any other person with access to any part of the Website and the Application in breach of the Terms of Service, your right to use the Website and the Application will cease immediately and you must, at our option, return or destroy any copies of the materials you have made. No right, title or interest in or to the Website and the Application or any content on the Website and the Application is transferred to you, and all rights not expressly granted are reserved by the Company. Any use of the Website and the Application not expressly permitted by these Terms of Service is a breach of these Terms of Service and may violate copyright, trademark and other laws.</li>
                </ol>
            </p>

            <h2>6. Trademarks</h2>
            <p>The Company name and all related names, logos, product and service names, designs and slogans are trademarks of the Company or its affiliates or licensors. You must not use such marks without the prior written permission of the Company. All other names, logos, product and service names, designs and slogans on this Application are the trademarks of their respective owners.</p>

            <h2>7. Prohibited Uses</h2>
            <p>The Company name and all related names, logos, product and service names, designs and slogans are trademarks of the Company or its affiliates or licensors. You must not use such marks without the prior written permission of the Company. All other names, logos, product and service names, designs and slogans on this Application are the trademarks of their respective owners.</p>
            <p>
                <ol>
                    <li>
                        Prohibited Uses. You may use the Website and the Application only for lawful purposes and in accordance with these Terms of Service. You agree not to use the Website and the Application:
                        <ul>
                            <li>(a) in any way that violates any applicable federal, state, local or international law or regulation (including, without limitation, any laws regarding the export of data or software to and from the US or other countries).</li>
                            <li>(b) for the purpose of exploiting, harming or attempting to exploit or harm minors in any way by exposing them to inappropriate content, asking for personally identifiable information or otherwise.</li>
                            <li>(c) to stalk, abuse, use profanity, send sexually explicit communication, threaten, intimidate, act in a rude, vulgar, sexist, or derogatory manner, defame, insult, make racially offensive statements, or otherwise harass any user or other individual.</li>
                            <li>(d) to send, knowingly receive, upload, download on the Website and the Application, use or re-use any material which does not comply with the Content Standards set out in these Terms.</li>
                            <li>(e) to transmit, or procure the sending of, any advertising or promotional material without our prior written consent, including any “junk mail”, “chain letter” or “spam” or any other similar solicitation.</li>
                            <li>(f) to impersonate or attempt to impersonate the Company, a Company employee, another user or any other person or entity (including, without limitation, by using e-mail addresses associated with any of the foregoing).</li>
                            <li>(g) to engage in any other conduct that restricts or inhibits anyone’s use or enjoyment of the Website and the Application, or which, as determined by us, may harm the Company or users of the Website and the Application or expose them to liability.</li>
                            <li>(h) Not to solicit other Users of the Website and the Application into engaging into an illegal and unlawful activities and not to conspire with other users into soliciting or committing such activities.</li>
                            <li>(i) to ask other users or any third parties for sexual favors through the Website and the Application.</li>
                        </ul>
                    </li>
                    <li>
                        Additionally, you agree not to:
                        <ul>
                            <li>(a) use the Website and the Application in any manner that could disable, overburden, damage, or impair the Website and the Application or interfere with any other party’s use of the Website and the Application, including their ability to engage in real time activities through the Website and the Application.</li>
                            <li>(b) use any robot, spider or other automatic device, process or means to access the Website and the Application for any purpose, including monitoring or copying any of the material on the Website and the Application.</li>
                            <li>(c) use any manual process to monitor or copy any of the material on the Website and the Application or for any other unauthorized purpose without our prior written consent.</li>
                            <li>(d) use any device, software or routine that interferes with the proper working of the Website and the Application. Introduce any viruses, trojan horses, worms, logic bombs or other material which is malicious or technologically harmful.</li>
                            <li>(e) attempt to gain unauthorized access to, interfere with, damage or disrupt any parts of the Website and the Application, the server on which the Website and the Application is stored, or any server, computer or database connected to the Website and the Application.</li>
                            <li>(f) attack the Website and the Application via a denial-of-service attack or a distributed denial-of-service attack. Otherwise attempt to interfere with the proper working of the Website and the Application.</li>
                            <li>(g) modify, adapt, sublicense, translate, sell, reverse engineer, decipher, decompile or otherwise disassemble any portion of the Website and the Application or any software used on or for the Service or the Website and the Application, or cause others to do so.</li>
                            <li>(f) forge headers or otherwise manipulate identifiers in order to disguise the origin of any information transmitted to or through the Website and the Application (either directly or indirectly through use of third party software).</li>
                            <li>(h) use meta tags or code or other devices containing any reference to the Website and the Application to direct any person to any website for any purpose.</li>
                            <li>(i) use the Website and the Application for cross site scripting, SQL injection and/or session Hijaking.</li>
                            <li>(j) tap into other users’ audio and video conversations.</li>
                        </ul>
                    </li>
                </ol>
            </p>

            <h2>8. User Contributions</h2>
            <p>
                <ol>
                    <li>User Contributions Defined. The Website and the Application may contain message boards, personal web pages or profiles, forums, bulletin boards, and other interactive features (collectively, “Interactive Services”) that allow users to post, submit, publish, display or transmit to other users or other persons (hereinafter, “post”) content or materials (collectively, “User Contributions”) on or through the Website and the Application.</li>
                    <li>Compliance with the Terms of Service. All User Contributions must comply with the Content Standards set out in these Terms of Service.</li>
                    <li>License to User Contribution. Any User Contribution you post to the site will be considered non-confidential and non-proprietary. By sharing any User Contribution through the Website and the Application, you grant us and our affiliates and service providers, and each of their and our respective licensees, successors and assigns the exclusive, unlimited, transferable, sub-licensable worldwide right to use, reproduce, modify, perform, display, distribute and otherwise disclose to third parties any such material for any purpose/according to your account settings.</li>
                    <li>
                        User Representation and Warranties. You represent and warrant that:
                        <ul>
                            <li>(a) You own or control all rights in and to the User Contributions and have the right to grant the license granted above to us and our affiliates and service providers, and each of their and our respective licensees, successors and assigns.</li>
                            <li>(b) All of your User Contributions do and will comply with these Terms of Service. You understand and acknowledge that you are responsible for any User Contributions you submit or contribute, and you, not the Company, have fully responsibility for such content, including its legality, reliability, accuracy and appropriateness.</li>
                            <li>(c) That you will not provide your account details and log-in information to third parties and that you will not log into the Application through another user’s account.</li>
                        </ul>
                    </li>
                    <li>Company Not Liable for User Contributions. We are not responsible, or liable to any third party, for the content or accuracy of any User Contributions posted by you or any other user on the Website and the Application.</li>
                </ol>
            </p>

            <h2>9. Monitoring and Enforcement; Termination</h2>
            <p>
                <ol>
                    <li>
                        Enforcement. We have the right to:
                        <ul>
                            <li>(a) remove any User Contributions for any or no reason in our sole discretion.</li>
                            <li>(b) take any action with respect to any User Contribution that we deem necessary or appropriate in our sole discretion, including if we believe that such User Contribution violates the Terms of Service, including the Content Standards, set out in Sec. 9.1. below, infringes any intellectual property right, right of publicity or other right of any person or entity, threatens the personal safety of users of the Website and the Application or the public or could create liability for the Company.</li>
                            <li>(b) disclose your identity or other information about you to any third party who claims that material posted by you violates their rights, including their intellectual property rights or their right to privacy.</li>
                            <li>(c) take appropriate legal action, including without limitation, referral to law enforcement, for any illegal or unauthorized use of the Website and the Application.</li>
                            <li>(d) terminate or suspend your access to all or part of the Website and the Application for any or no reason, including without limitation, any violation of these Terms of Service.</li>
                            <li>(e) without limiting the foregoing, we have the right to fully cooperate with any law enforcement authorities or court order requesting or directing us to disclose the identity or other information of anyone posting any materials on or through the Website and the Application. YOU WAIVE AND HOLD HARMLESS THE COMPANY AND ITS AFFILIATES, LICENSEES AND SERVICE PROVIDERS FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY THE COMPANY/ANY OF THE FOREGOING PARTIES DURING OR AS A RESULT OF ITS INVESTIGATIONS AND FROM ANY ACTIONS TAKEN AS A CONSEQUENCE OF INVESTIGATIONS BY EITHER THE COMPANY/SUCH PARTIES OR LAW ENFORCEMENT AUTHORITIES.</li>
                        </ul>
                    </li>
                    <li>No Liability. However, we do not undertake to review the material before it is posted on the Application, and cannot ensure prompt removal of objectionable material after it has been posted. Accordingly, we assume no liability for any action or inaction regarding transmissions, communications or content provided by any user or third party. We have no liability or responsibility to anyone for performance or nonperformance of the activities described in this section.</li>
                </ol>
            </p>

            <h2>10. Content Standards</h2>
            <p>
                <ol>
                    <li>
                        Content Standards. These content standards apply to any and all User Contributions and use of Interactive Services. User Contributions must in their entirety comply with all applicable federal, state, local and international laws and regulations. Without limiting the foregoing, User Contributions must not:
                        <ul>
                            <li>(a) contain any material which is defamatory, obscene, indecent, abusive, offensive, harassing, violent, hateful, inflammatory or otherwise objectionable.</li>
                            <li>(b) promote sexually explicit or pornographic material, violence, or discrimination based on race, sex, religion, nationality, disability, sexual orientation or age.</li>
                            <li>(c) infringe any patent, trademark, trade secret, copyright or other intellectual property or other rights of any other person.</li>
                            <li>(d) violate the legal rights (including the rights of publicity and privacy) of others or contain any material that could give rise to any civil or criminal liability under applicable laws or regulations or that otherwise may be in conflict with these Terms of Service and our Privacy Policy.</li>
                            <li>(e) be likely to deceive any person.</li>
                            <li>(f) promote any illegal activity, or advocate, promote or assist any unlawful act.</li>
                            <li>(g) cause annoyance, inconvenience or needless anxiety or be likely to upset, embarrass, alarm or annoy any other person.</li>
                            <li>(h) impersonate any person, or misrepresent your identity or affiliation with any person or organization.</li>
                            <li>(i) involve commercial activities or sales, such as contests, sweepstakes and other sales promotions, barter or advertising.</li>
                            <li>(j) give the impression that they emanate from or are endorsed by us or any other person or entity, if this is not the case.</li>
                        </ul>
                    </li>
                    <li>Content not meeting the standards. Any content not meeting the above standards will be promptly removed according to Sec. IX.</li>
                </ol>
            </p>

            <h2>11. Linking to the Application and Social Media Features</h2>
            <p>
                <ol>
                    <li>Linking to the Application. You may link to our Website and Application, provided you do so in a way that is fair and legal and does not damage our reputation or take advantage of it, but you must not establish a link in such a way as to suggest any form of association, approval or endorsement on our part without our express written consent.</li>
                    <li>
                        Social Media. The Website and the Application may provide certain social media features that enable you to:
                        <ul>
                            <li>(a) link from your own or certain third-party websites to certain content on this Website and Application.</li>
                            <li>(b) send e-mails or other communications with certain content, or links to certain content, on this Website and Application.</li>
                            <li>(c) cause limited portions of content on this Website and Application to be displayed or appear to be displayed on your own or certain third-party websites.</li>
                            <li>
                                (d) you may use these features solely as they are provided by us and solely with respect to the content they are displayed with and otherwise in accordance with any additional terms and conditions we provide with respect to such features. Subject to the foregoing, you must not:
                                <ul>
                                    <li>Establish a link from any website that is not owned by you.</li>
                                    <li>Cause the Website and Application or portions of them to be displayed, or appear to be displayed by, for example, framing, deep linking or in-line linking, on any other site.</li>
                                    <li>Link to any part of the Website and the Application other than the homepage.</li>
                                    <li>Otherwise take any action with respect to the materials on this Website and the Application that are inconsistent with any other provision of these Terms of Service.</li>
                                    <li>The Website and the Application from which you are linking, or on which you make certain content accessible, must comply in all respects with the Content Standards set out in these Terms of Service.</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>Unauthorized Framing or Linking. You agree to cooperate with us in causing any unauthorized framing or linking immediately to cease. We reserve the right to withdraw linking permission without notice. We may disable all or any social media features and any links at any time without notice in our discretion.</li>
                    <li>Linking from the Application. We do not take responsibility and do not endorse any third party websites, services and content available on or linked to by the Website and the Application.</li>
                </ol>
            </p>

            <h2>12. Copyright Infringement</h2>
            <p>
                <ol>
                    <li>
                        Reporting Claims to Copyright Infringements. We take claims of copyright infringement seriously. We will respond to notices of alleged copyright infringement that comply with applicable law. If you believe any materials accessible on or from the Website or the Application infringe your copyright, you may request removal of those materials (or access thereto) from the Website or the Application by submitting written notification to our Copyright Agent (designated below), which must include substantially the following:
                        <ul>
                            <li>(a) Your physical or electronic signature.</li>
                            <li>(b) Identification of the copyrighted work you believe to have been infringed or, if the claim involves multiple works on the Website or the Application, a representative list of such works.</li>
                            <li>(c) Identification of the material you believe to be infringing in a sufficiently precise manner to allow us to locate that material.</li>
                            <li>(d) Adequate information by which we can contact you (including your name, postal address, telephone number and, if available, e-mail address).</li>
                            <li>(e) A statement that you have a good faith belief that use of the copyrighted material is not authorized by the copyright owner, its agent or the law.</li>
                            <li>(f) A statement that the information in the written notice is accurate.</li>
                            <li>(g) A statement, under penalty of perjury, that you are authorized to act on behalf of the copyright owner.</li>
                        </ul>
                        <p>
                            Our designated Copyright Agent to receive such written notifications is:
                            <br/>
                            John White
                            <br/>
                            Email: support@tattoon.net
                        </p>

                        <p>Please be aware that if you knowingly materially misrepresent that material or activity on the Website and the Application is infringing your copyright, you may be held liable for damages.</p>
                    </li>

                    <li>
                        Counter-Notification Procedures. If you believe that material you posted on the Application was removed or access to it was disabled by mistake or misidentification, you may file a counter-notification with us (“Counter-Notice”) by submitting written notification to our copyright agent (identified below). The Counter-Notice must include substantially the following:
                        <ul>
                            <li>(a) Your physical or electronic signature.</li>
                            <li>(b) An identification of the material that has been removed or to which access has been disabled and the location at which the material appeared before it was removed or access disabled.</li>
                            <li>(c) Adequate information by which we can contact you (including your name, postal address, telephone number and, if available, e-mail address).</li>
                            <li>(d) A statement under penalty of perjury by you that you have a good faith belief that the material identified above was removed or disabled as a result of a mistake or misidentification of the material to be removed or disabled.</li>
                            <li>(e) A statement that you will consent to the jurisdiction of any judicial district in which the Website and the Application may be found and that you will accept service from the person (or an agent of that person) who provided the Website and the Application with the complaint at issue.</li>
                        </ul>
                        <p>
                            Completed Counter-Notices should be sent to:
                            <br/>
                            John White
                            <br/>
                            Email: support@tattoon.net
                        </p>
                        <p>We reserve the right to restore the removed content if the party filing the original Notice does not file a court action against you within ten business days of receiving the copy of your Counter-Notice.</p>
                        <p>Please be aware that if you knowingly materially misrepresent that material or activity on the Application was removed or disabled by mistake or misidentification, you may be held liable for damages.</p>
                    </li>
                </ol>
            </p>

            <h2>13. Disclaimer of Warranties</h2>
            <p>
                <ol>
                    <li>No Liability for Technologically Harmful Material. You understand that we cannot and do not guarantee or warrant that files available for downloading from the internet or the Website and the Application will be free of viruses or other destructive code. You are responsible for implementing sufficient procedures and checkpoints to satisfy your particular requirements for anti-virus protection and accuracy of data input and output, and for maintaining a means external to our site for any reconstruction of any lost data. WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR EQUIPMENT, PROGRAMS, DATA OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE WEBSITE AND THE APPLICATION OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE AND THE APPLICATION OR YOUR DOWNLOADING OF ANY MATERIAL POSTED ON THEM.</li>
                    <li>
                        YOUR USE OF THE WEBSITE AND THE APPLICATION, THEIR CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE AND THE APPLICATION IS AT YOUR OWN RISK. THE WEBSITE AND THE APPLICATION, THEIR CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE AND THE APPLICATION ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER THE COMPANY NOR ANY PERSON ASSOCIATED WITH THE COMPANY MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY OR AVAILABILITY OF THE WEBSITE AND THE APPLICATION. WITHOUT LIMITING THE FOREGOING, NEITHER THE COMPANY NOR ANYONE ASSOCIATED WITH THE COMPANY REPRESENTS OR WARRANTS THAT THE WEBSITE AND THE APPLICATION, THEIR CONTENT OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE AND THE APPLICATION WILL BE ACCURATE, RELIABLE, ERROR-FREE OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR SITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE WEBSITE AND THE APPLICATION OR ANY SERVICES OR ITEMS OBTAINED THROUGH THEM WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.
                        <p>THE COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT AND FITNESS FOR PARTICULAR PURPOSE.</p>
                        <p>THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.</p>
                    </li>
                </ol>
            </p>

            <h2>14. Limitation of Liability</h2>
            <p>IN NO EVENT WILL THE COMPANY, ITS AFFILIATES OR THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE WEBSITE AND THE APPLICATION, ANY WEBSITES AND THE APPLICATION LINKED TO THEM, ANY CONTENT ON THE WEBSITE AND THE APPLICATION OR SUCH OTHER WEBSITES OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE AND THE APPLICATION, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT OR OTHERWISE, EVEN IF FORESEEABLE.</p>
            <p>THE FOREGOING DOES NOT AFFECT ANY LIABILITY WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.</p>

            <h2>15. Indemnification</h2>
            <p>You agree to defend, indemnify and hold harmless the Company, its affiliates, licensors and service providers, and its and their respective officers, directors, employees, contractors, agents, licensors, suppliers, successors and assigns from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses or fees (including reasonable attorneys’ fees) arising out of or relating to your violation of these Terms of Service or your use of the Website and the Application, including, but not limited to, your User Contributions, any use of the Website’s and Application’s content, services and products other than as expressly authorized in these Terms of Service or your use of any information obtained from the Website and the Application.</p>

            <h2>16. Miscellaneous</h2>
            <p>
                <ol>
                    <li>
                        Governing Law and Jurisdiction. All matters relating to the Website and the Application and these Terms of Service and any dispute or claim arising therefrom or related thereto (in each case, including non-contractual disputes or claims), shall be governed by and construed in accordance with the internal laws of the State of California without giving effect to any choice or conflict of law provision or rule (whether of the State of California or any other jurisdiction).
                        <p>Any legal suit, action or proceeding arising out of, or related to, these Terms of Service or the Application shall be instituted exclusively in the federal courts of the United States or the courts of the State of California although we retain the right to bring any suit, action or proceeding against you for breach of these Terms of Service in your country of residence or any other relevant country. You waive any and all objections to the exercise of jurisdiction over you by such courts and to venue in such courts.</p>
                    </li>
                    <li>
                        Waiver and Severability. No waiver of by the Company of any term or condition set forth in these Terms of Service shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure of the Company to assert a right or provision under these Terms of Service shall not constitute a waiver of such right or provision.
                        <p>If any provision of these Terms of Service is held by a court or other tribunal of competent jurisdiction to be invalid, illegal or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of the Terms of Service will continue in full force and effect.</p>
                    </li>
                    <li>Entire Agreement. The Terms of Service and our Privacy Policy constitute the sole and entire agreement between you and Company with respect to the Website and the Application and supersede all prior and contemporaneous understandings, agreements, representations and warranties, both written and oral, with respect to the Application.</li>
                    <li>Amendments. Please note that we reserve the right to revise and update these ToS from time to time in our sole discretion. All changes are effective immediately when we post them, and apply to all access to and use of the Application thereafter.</li>
                    <li>
                        Your Comments and Concerns.
                        <br/>
                        The Website and the Application are operated by Urbo Labs, Inc.
                    </li>
                </ol>
            </p>

            <p>
                Urbo Labs, Inc.
                <br/>
                919 North Market Street, Suite 950
                <br/>
                City of Wilmington, County of New Castle, Delaware, 19801
            </p>

            <p>All feedback, comments, requests for technical support and other communications regarding the Website and the Application should be sent to: support@tattoon.net</p>
            <p>Thank you for visiting the Website and using the Application.</p>
        </div>
    </div>
);

export default TermsOfUse;