import React, { useEffect } from 'react';
import classNames from "classnames";
import { Routes, Route, Navigate, useLocation, Link } from 'react-router-dom';

import styles from './app.module.scss';
import { Home, PrivacyPolicy, TermsOfUse } from './pages';
import { Instagram, Twitter, Tiktok } from './icons';
import { isAndroidDevice } from "./utils";
import { GOOGLE_DOWNLOAD_LINK, APPLE_DOWNLOAD_LINK } from './constants';

const isDeviceAndroid = isAndroidDevice();

const App = () =>  {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const downloadLinkUrl = isDeviceAndroid ? GOOGLE_DOWNLOAD_LINK : APPLE_DOWNLOAD_LINK;

  return (
    <div className={classNames(styles.appContainer, { [styles.solid]: pathname !== '/' })}>
      <img src="/assets/gradient.webp" className={styles.background} />
      <div className={styles.layout}>
        <header className={styles.header}>
          <Link to="/" className={styles.logo}>
            <img src="/assets/logo.png" className={styles.image} />
            tattoon
          </Link>

          <a href={downloadLinkUrl} target="_blank" className={styles.downloadLink}>
            <button className={styles.button}>download</button>
          </a>
        </header>

        <div className={styles.content}>
          <Routes>
            <Route
              path='/'
              element={<Home />}
            />
            <Route
              path='/privacy-policy'
              element={<PrivacyPolicy />}
            />
            <Route
              path='/terms-of-use'
              element={<TermsOfUse />}
            />
            <Route
              path="*"
              element={<Navigate to="/" replace />}
            />
          </Routes>
        </div>
      </div>

      <div className={styles.footer}>
        <div className={styles.content}>
          <a href="https://drive.google.com/drive/folders/1BFDw-YgW4_j1XIuvpeTjQSL4wZIin9Mi?usp=sharing" target="_blank" className={classNames(styles.downloadLink, styles.marketingKit)}>
            <button className={styles.button}>marketing kit</button>
          </a>

          <div className={styles.column}>
            <span className={styles.title}>Follow us</span>
            <a href="https://www.instagram.com/tattoon.app_official/" target="_blank" className={styles.link}>
              <Instagram className={styles.icon} />
              Instagram
            </a>
            <a href="https://www.tiktok.com/" target="_blank" className={styles.link}>
              <Tiktok className={styles.icon} />
              TikTok
            </a>
            <a href="https://x.com/Alex_Urban_1998" target="_blank" className={styles.link}>
              <Twitter className={styles.icon} />
              Twitter
            </a>
          </div>

          <div className={styles.column}>
            <span className={styles.title}>About us</span>
            <Link to='/terms-of-use' className={styles.link}>
              Terms of use
            </Link>
            <Link to="/privacy-policy" className={styles.link}>
              Privacy policy
            </Link>
          </div>

          <div className={styles.column}>
            <span className={styles.title}>Reach us</span>
            <a href={`mailto:urban@tattoon.net`} className={styles.link}>
              Support
            </a>
            <a href={`mailto:urban@tattoon.net`} className={styles.link}>
              For investors
            </a>
          </div>

          <div className={styles.column}>
            <a href="https://drive.google.com/drive/folders/1BFDw-YgW4_j1XIuvpeTjQSL4wZIin9Mi?usp=sharing" target="_blank" className={styles.downloadLink}>
              <button className={styles.button}>marketing kit</button>
            </a>
            <span className={styles.title}>© 2024 Urbo Labs, Inc.</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
