import React from 'react';
import classNames from "classnames";
import Slider from 'react-infinite-logo-slider'

import { CAROUSEL_ITEMS } from './constants';
import styles from './styles.module.scss';

const Carousel = ({ className }) => {
    return <div className={classNames(styles.slider, className)}>
        <Slider className={classNames(styles.slider, className)} duration={40} width="123px">
            {CAROUSEL_ITEMS.map(({ url, title }) => <Slider.Slide>
                <div className={styles.slide}>
                    <img src={url} className={styles.image} />
                    <span className={styles.title}>{title}</span>
                </div>
            </Slider.Slide>)}
        </Slider>
    </div>
};

export default Carousel;
